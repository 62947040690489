<template>
    <div>
        <Navbar page="Direitos dos titulares" link="/cadastros" nameLink="Cadastros" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-2">
                            <div class="col-span-12 md:col-span-1">
                                <label for="ref" class="block text-sm font-medium">Ref</label>
                                <input v-model="form.ref" type="text" name="ref" id="ref" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div class="col-span-12 md:col-span-11">
                                <label for="direito" class="block text-sm font-medium">Direito</label>
                                <input v-model="form.direito" type="text" name="direito" id="direito" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div class="col-span-12 md:col-span-12">
                                <label for="detalhes" class="block text-sm font-medium">Detalhes</label>
                                <textarea v-model="form.detalhes" type="text" rows="3" name="detalhes" id="detalhes" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                            </div> 
                            <div class="col-span-6 md:col-span-6">
                                <button @click="adicionarPositivo" type="button" class="inline-flex justify-center my-2 py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                    Adicionar uma ação em caso positivo
                                </button>
                            </div> 
                            <div class="col-span-12 md:col-span-12" v-for="(acao, idx) in form.acoesPositivo" :key="`p${idx}`">
                                <label :for="`acaoPositivo${idx}`" class="block text-sm font-medium">Ação se caso encontrar
                                    <button @click="excluir('acoesPositivo', idx)" type="button" class="inline-flex justify-center ml-2 py-1 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-red-400">
                                        excluir
                                    </button>
                                </label>
                                <textarea v-model="form.acoesPositivo[idx]" type="text" rows="3" :name="`acaoPositivo${idx}`" :id="`acaoPositivo${idx}`" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                            </div> 
                            <div class="col-span-6 md:col-span-6">
                                <button @click="adicionarNegativo" type="button" class="inline-flex justify-center my-2 py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                    Adicionar uma ação em caso negativo
                                </button>
                            </div> 
                            <div class="col-span-12 md:col-span-12" v-for="(acao, idx) in form.acoesNegativo" :key="`n${idx}`">
                                <label :for="`acaoNegativo${idx}`" class="block text-sm font-medium">Ação se caso <b>NÃO</b> encontrar
                                    <button @click="excluir('acoesNegativo', idx)" type="button" class="inline-flex justify-center ml-2 py-1 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-red-400">
                                        excluir
                                    </button>
                                </label>
                                <textarea v-model="form.acoesNegativo[idx]" type="text" rows="3" :name="`acaoNegativo${idx}`" :id="`acaoNegativo${idx}`" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                            </div> 
                        </div>

                        <div class="grid grid-cols-12 gap-2">
                            <div class="col-span-6 md:col-span-6">
                                <button @click="adicionarEmailPositivo" type="button" class="inline-flex justify-center my-2 py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                    Adicionar um email em caso positivo
                                </button>
                            </div> 
                            <div class="col-span-12 md:col-span-12" v-for="(emailP, idx) in form.emailsPositivo" :key="`ep${idx}`">
                                <label :for="`emailPositivo${idx}`" class="block text-sm font-medium">Email se caso encontrar
                                    <button @click="excluir('emailsPositivo', idx)" type="button" class="inline-flex justify-center ml-2 py-1 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-red-400">
                                        excluir
                                    </button>
                                </label>
                                <VueEditor v-model="form.emailsPositivo[idx]" class="mt-2" :name="`emailPositivo${idx}`" :id="`emailPositivo${idx}`"></VueEditor>
                            </div> 
                            
                            <div class="col-span-6 md:col-span-6">
                                <button @click="adicionarEmailNegativo" type="button" class="inline-flex justify-center my-2 py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                    Adicionar um email em caso negativo
                                </button>
                            </div> 
                            <div class="col-span-12 md:col-span-12" v-for="(emailN, idx) in form.emailsNegativo" :key="`en${idx}`">
                                <label :for="`emailNegativo${idx}`" class="block text-sm font-medium">Email se caso <b>NÃO</b> encontrar
                                    <button @click="excluir('emailsNegativo', idx)" type="button" class="inline-flex justify-center ml-2 py-1 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-red-400">
                                        excluir
                                    </button>
                                </label>
                                <VueEditor v-model="form.emailsNegativo[idx]" class="mt-2" :name="`emailNegativo${idx}`" :id="`emailNegativo${idx}`"></VueEditor>
                            </div> 
                        
                        </div>
                    </div>                
                    <div class="grid grid-cols-2">
                        <div class="px-5 py-3 text-left sm:px-6">
                            <button @click="$router.back()" type="button" class="inline-flex justify-center my-2 py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="px-5 py-3 text-right sm:px-6">
                            <button @click="save" type="button" class="inline-flex justify-center my-2 py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Salvar
                            </button>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            html: '',
            route: 'direitos',
            form: {
                ref: '',
                direito: '',
                detalhes: '',
                acoesPositivo: [],
                acoesNegativo: [],
                emailsPositivo: [],
                emailsNegativo: [],
            },
        }
    },
    methods: {
        async save() {
            const method = this.form._id ? 'put' : 'post'

            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        adicionarPositivo() {
            this.form.acoesPositivo.push('');
        },
        adicionarNegativo() {
            this.form.acoesNegativo.push('');
        },
        adicionarEmailPositivo() {
            this.form.emailsPositivo.push('');
        },
        adicionarEmailNegativo() {
            this.form.emailsNegativo.push('');
        },
        excluir(qual, idx) {
            this.form[qual].splice(idx, 1);
        }
    },
    async beforeMount() {
        const id = this.$route.params.id;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
            console.log(this.form);
        }
    },
}
</script>